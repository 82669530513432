import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/brick_finance_customer_story_featured_image.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(process.env.SIGNUP_URL);
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const Brick = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="How Brick uses Camelo to ensure 24/7 coverage while maintaining compliance & team well-being"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Brick uses Camelo to ensure 24/7 coverage while
                    maintaining compliance & team well-being
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-15 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-10 pt-lg-15 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://www.onebrick.io/" target="__blank">
                      {`Brick `}
                    </Link>
                    is a leading fintech company in Southeast Asia, focused on
                    building a reliable real-time payments network through open
                    finance solutions. Their rapid growth has brought challenges
                    in maintaining 24/7 coverage, ensuring compliance, and
                    supporting team well-being. By switching to Camelo, Donnie
                    Silalahi, Head of Department at Brick, has saved about 5
                    hours per week on{" "}
                    <Link
                      to="https://camelohq.com/features/scheduling-software"
                      target="__blank"
                    >
                      {`employee scheduling`}
                    </Link>
                    .
                  </p>

                  <h4 className="pt-8 pb-4">
                    Supporting diverse clients with reliable financial services
                  </h4>
                  <p className="gr-text-8 text-black">
                    Starting in Indonesia, Brick aims to make financial services
                    accessible to everyone in Southeast Asia. They work with a
                    diverse range of clients, from startups to large financial
                    institutions.
                  </p>
                  <p className="gr-text-8 text-black">
                    For example, they helped a payroll provider achieve accurate
                    salary disbursements while cutting transaction costs. A
                    pay-later service used Brick's API to speed up transactions
                    and boost reliability on e-commerce platforms. Brick also
                    helped a marketplace enable 24/7 payments with greater
                    stability. Their single API connects to various banks and
                    e-wallets, offering better conversion rates than other
                    alternatives.
                  </p>

                  <h4 className="pt-8 pb-4">In fintech, every second counts</h4>
                  <p className="gr-text-8 text-black">
                    “As a team leader in Brick's Payment Operations, I've
                    witnessed our rapid growth firsthand and faced the
                    challenges that come with scaling a fintech operation in a
                    dynamic market,” Donnie shared.
                  </p>
                  <p className="gr-text-8 text-black">
                    One major challenge is staying compliant with the varying
                    rules and regulations across different countries in the
                    payments network. Another difficulty is managing sudden
                    spikes in transaction volumes, such as during major
                    e-commerce events or when onboarding high-volume clients.
                    The team needs robust systems and 24/7 support.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "I've learned that in fintech, every second counts – a
                      delayed transaction can mean lost business for our clients
                      and a damaged reputation for us."
                    </p>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    To address these challenges, Brick implemented smarter alert
                    systems and scheduled the team for around-the-clock support.
                    This also introduced new challenges, like balancing work
                    schedules and maintaining a healthy work-life balance for
                    the team.
                  </p>

                  <p className="gr-text-8 text-black">
                    “Balancing growth, regulatory compliance, and team
                    well-being has been a complex but rewarding journey,” Donnie
                    added.
                  </p>

                  <h4 className="pt-8 pb-4">
                    A breath of fresh air, away from tedious spreadsheets
                  </h4>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “When we first implemented Camelo, it was a revelation for
                      our Payment Operations team. Coming from a tedious
                      spreadsheet-based system, the intuitive interface was a
                      breath of fresh air.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    What immediately impressed the team was how easily they
                    could navigate to the current day and see active shifts on
                    Camelo. This simple feature saved them countless hours of
                    frustration by giving them an overview of daily operations
                    right away.
                  </p>
                  <p className="gr-text-8 text-black">
                    Plus, the improved visibility into shift patterns has helped
                    management at Brick support a healthier work-life balance
                    for their team. In a high-pressure, 24/7 fintech
                    environment, smart shift scheduling can help prevent
                    burnout. This, in turn, helps them attract and retain top
                    talent.
                  </p>

                  <h4 className="pt-8 pb-4">
                    How Brick stays efficient and fair
                  </h4>
                  <p className="gr-text-8 text-black">
                    “The real-time
                    <Link
                      to="https://camelohq.com/features/time-and-attendance"
                      target="__blank"
                    >
                      {` clock-in tracking feature `}
                    </Link>
                    was a game-changer. We could immediately see who was on time
                    and who wasn't, which is crucial for maintaining our 24/7
                    support for our real-time payments network,” Donnie said.
                    This feature also allows them to quickly adjust for late
                    arrivals, so they always have the coverage they need. And
                    with the shift swap and leave request features, they can
                    handle personal commitments without sacrificing
                    round-the-clock coverage.
                  </p>
                  <p className="gr-text-8 text-black">
                    The timesheet calculation feature was also impressive to the
                    people at Brick. They can accurately track time spent on
                    tasks, which is essential when they're processing a high
                    volume of transactions and need to allocate resources
                    efficiently. Plus, seeing how shift hours are distributed
                    helps ensure fair scheduling as the team grows.
                  </p>

                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “What I appreciate most about Camelo is how it’s
                      streamlined our entire shift management process, crucial
                      for a fast-growing fintech like Brick. The feature I find
                      really valuable is the task management system. Being able
                      to create detailed SOP checklists for each shift and see
                      them completed in real-time has helped us maintain our
                      high-quality services and regulatory compliance.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Saving 5 hours a week for more important commitments
                  </h4>
                  <p className="gr-text-8 text-black">
                    Camelo has been a great source of support for Brick’s
                    growth. Donnie told us about seeing a decrease in scheduling
                    errors and last-minute shift changes. It means better
                    service for their clients and optimal staffing while
                    processing high volumes of payments annually.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “For me, it’s saved about 5 hours a week that I can now
                      spend on strategies that improve our services and
                      operations. The smooth scheduling, time tracking, and task
                      management give us peace of mind, especially as we deal
                      with complex regulations and scale up.”
                    </p>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default Brick;
